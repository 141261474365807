import React from "react";
import {
  Divider,
  Flex,
  Image,
  Text,
  Box,
  Progress,
  Stepper,
  Step,
  StepIndicator,
  StepStatus,
  StepIcon,
  StepTitle,
  StepSeparator,
  StepNumber,
  Alert,
  AlertIcon,
  AlertTitle,
  Button,
} from "@chakra-ui/react";
import { MdOutlineArrowBack } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import SubmittedApprovedAppllications from "./SubmittedApprovedAppllications";

const StudentApplicationFormHeader = ({
  logo,
  signOut,
  error,
  errorsArray,
  steps,
  activeStep,
  setActiveStep,
  progressPercent,
  orientation,
  submitted,
  formSave,
  noData,
  approved,
  declined,
  comment,
}) => {
  const navigate = useNavigate();

  return (
    <>
      <Flex
        bg={"#D734390A"}
        h={"100px"}
        alignItems={"center"}
        px={["30px", "40px", "40px", "50px"]}
      >
        <Image
          src={logo}
          alt="logo"
          onClick={() => navigate(0)}
          cursor={"pointer"}
        />
        <Text
          fontWeight={"400"}
          fontSize={"12px"}
          lineHeight={"18.35px"}
          color={"#1E1E1E"}
          opacity={"0.5"}
        >
          INSTITUTE OF CHATERED SECRETARIES AND ADMINISTRATORS OF NIGERIA
        </Text>
      </Flex>
      <Divider border={"2px solid #A6A6A6"} opacity={"0.2"} />
      <Flex
        alignItems={"center"}
        px={["30px", "40px", "40px", "50px"]}
        py={"20px"}
        gap={"10px"}
        cursor={"pointer"}
        onClick={signOut}
      >
        <MdOutlineArrowBack style={{ width: "20px", height: "20px" }} />
        <Text
          color={"#54595F"}
          fontWeight={"400"}
          fontSize={"18px"}
          lineHeight={"21.09px"}
        >
          Logout
        </Text>
      </Flex>
      <Divider border={"2px solid #A6A6A6"} opacity={"0.2"} />
      {error ? (
        <Alert status="error" className="mt-3" mb={3}>
          <AlertIcon />
          <AlertTitle>{errorsArray.join(", ")}</AlertTitle>
        </Alert>
      ) : submitted ? (
        <Flex justifyContent={"center"} alignItems={"center"} mt={10}>
          <Text
            fontWeight={"600"}
            fontSize={["24px", "28px", "32px"]}
            lineHeight={"33.6px"}
            color={"#22944E"}
          >
            Application Submitted successfully, Pending Approval
          </Text>
        </Flex>
      ) : approved ? (
        <SubmittedApprovedAppllications />
      ) : declined ? (
        <Flex
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          mt={10}
          fontWeight={"600"}
          gap={5}
          fontSize={["18px", "20px", "22px"]}
          lineHeight={"28.6px"}
          color={"red.500"}
        >
          <Text>
            {" "}
            Your Application has been Declined, find the reason below:
          </Text>
          <Text color={"black"}>Reason for Decline: {comment}</Text>
          <Button
            color={"green"}
            onClick={() => {
              navigate("/student-application-form-new");
              navigate(0);
            }}
          >
            {" "}
            Please Re-apply
          </Button>
        </Flex>
      ) : (
        <>
          <Flex px={["20px", "30px", "50px"]} py={"20px"}>
            <Text
              fontWeight={"700"}
              fontSize={["24px", "28px", "32px"]}
              lineHeight={"33.6px"}
              color={"#121212"}
              fontFamily={"roboto"}
            >
              Student Application Form
            </Text>
          </Flex>
          <Divider border={"2px solid #A6A6A6"} opacity={"0.2"} />
          <Box>
            <Stepper
              index={activeStep}
              px={["30px", "40px", "40px", "50px"]}
              py={"30px"}
              colorScheme="red"
              orientation={orientation}
            >
              {steps.map((step, index) => (
                <Step
                  key={index}x
                  cursor={"pointer"}
                >
                  <StepIndicator>
                    <StepStatus
                      complete={<StepIcon />}
                      incomplete={<StepNumber />}
                      active={<StepNumber />}
                    />
                  </StepIndicator>
                  <Box flexShrink="0">
                    <StepTitle
                      color={
                        activeStep === index || activeStep > index
                          ? "#D11A2A"
                          : "#B4B1B1"
                      }
                      fontSize={"16px"}
                      fontWeight={"500"}
                      lineHeight={"18.75px"}
                      fontFamily={"roboto"}
                    >
                      {step.title}
                    </StepTitle>
                  </Box>
                  <StepSeparator />
                </Step>
              ))}
            </Stepper>
            <Progress
              colorScheme="red"
              value={progressPercent}
              height="3px"
              width="full"
            />
            <Box mt={"50px"} mb={"20px"}>
              {steps[activeStep].form}
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

export default StudentApplicationFormHeader;
