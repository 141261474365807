import {
  Box,
  Flex,
  Image,
  List,
  ListIcon,
  ListItem,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { generateMenu, menu2 } from "./sideBarLinks";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import icsanLogo from "../../Assets/Images/icsan-logo.svg";
import { IoLogOut } from "react-icons/io5";
import { removeUser } from "../../redux/slices/authSlice";
import { useNavigate } from "react-router-dom";
import { BiCaretDown, BiCaretUp } from "react-icons/bi";

const Sidebar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedMenu, setSelectedMenu] = useState(null);
  const { pathname } = useLocation();
  const [isCollapsed] = useState(false);
  const [menu, setMenu] = useState("");
  const Permissions = useSelector(
    (state) => state.authPermissions?.Permissions
  );
  const userType = useSelector((state) => state.auth?.user?.userType);

  useEffect(() => {
    if (userType === "Administrative") {
      if (Permissions) {
        if (Array.isArray(Permissions)) {
          const result = generateMenu(Permissions);
          setMenu(result);
        } else if (Permissions) {
          // Convert Permissions into an array
          const result = generateMenu([Permissions]);
          setMenu(result);
        }
      }
    } else if (userType === "Member") {
      setMenu(menu2);
    }
  }, [Permissions, userType]);
  const handleMenuItemClick = (name) => {
    if (selectedMenu === name) {
      setSelectedMenu(null);
    } else {
      setSelectedMenu(name);
    }
  };

  const handleDropdownClick = (name) => {
    if (selectedMenu === name) {
      setSelectedMenu(null);
    } else {
      setSelectedMenu(name);
    }
  };

  const signOut = () => {
    dispatch(removeUser());
    localStorage.removeItem("persist:root");
    navigate("/");
  };

  return (
    <Flex
      as={"nav"}
      pt={5}
      direction="column"
      w={isCollapsed ? "5rem" : "258px"}
      bgColor={"#D73439"}
      h="100%"
      color={"rgba(30, 30, 30, 0.5)"}
      transition={"all 0.5s ease"}
      position={"fixed"}
      align={"center"}
      left={0}
      top={0}
      bottom={0}
      zIndex={100}
      display={["none", "none", "none", "flex"]}
    >
      <Box h="90px" bgColor={"white"} mb={5} p={5} w={"full"} mt={-5}>
        <Image
          src={icsanLogo}
          onClick={() => navigate(0)}
          cursor={"pointer"}
          ml={5}
          alt="ICSAN Logo"
        />
      </Box>
      <List
        mt={5}
        pt={5}
        pb={10}
        spacing={5}
        alignSelf={"stretch"}
        sx={{
          "& svg": {
            width: "1.5rem",
            height: "1.5rem",
            "& + span": {
              display: isCollapsed ? "none" : "inline",
            },
          },
          overflowY: "scroll",
          "&::-webkit-scrollbar": {
            width: "0px",
          },
          scrollbarWidth: "none",
        }}
      >
        {menu &&
          menu?.map((section) => (
            <React.Fragment key={section?.heading}>
              <Text px={5} fontWeight={"400"} fontSize={"12px"} color="#FFFFFF">
                {section?.heading}
              </Text>
              {section?.items?.map(({ name, link, icon, submenu }, index) => {
                return !submenu ? (
                  <ListItem
                    key={index}
                    as={NavLink}
                    to={link}
                    py={3}
                    display={"flex"}
                    justify={"center"}
                    position="relative"
                    onClick={() => handleMenuItemClick(name)}
                    w="full"
                    fontSize={"15px"}
                    borderLeftRadius={"10px"}
                    paddingTop={2}
                    paddingBottom={2}
                    marginLeft={2}
                    fontWeight={500}
                    bgColor={selectedMenu === name ? "white" : ""}
                  >
                    {({ isActive }) => (
                      <Flex
                        alignItems={"center"}
                        // bgColor={pathname.includes(link) ? "white" : ""}
                        color={
                          selectedMenu === name ? "#D11A2A" : "#FFFFFF"
                        }
                        minW={"80%"}
                        // mx="auto"
                        gap={3}
                        px={5}
                      >
                        <Box
                          position={"absolute"}
                          left={0}
                          width={"full"}
                          height={"full"}
                          zIndex={-1}
                        />
                        <ListIcon
                          as={icon}
                          m={0}
                          opacity={isCollapsed && !isActive && 0.5}
                        />
                        <Box as={"span"} flexGrow={1}>
                          {name}
                        </Box>
                      </Flex>
                    )}
                  </ListItem>
                ) : (
                  <React.Fragment>
                    <ListItem
                      key={index}
                      cursor={"pointer"}
                      py={3}
                      display={"flex"}
                      position="relative"
                      onClick={() => handleDropdownClick(name)}
                      mt={[1, 2, 5]}
                    >
                      <Box
                        w="full"
                        fontSize={"15px"}
                        borderLeftRadius={"10px"}
                        paddingTop={2}
                        paddingBottom={2}
                        marginLeft={2}
                        fontWeight={500}
                        bgColor={selectedMenu === name ? "white" : ""}
                      >
                        <Flex alignItems={"center"} minW={"80%"} gap={3} px={5}>
                          {pathname.includes("userManagement") ||
                          pathname.includes("FeeManagement") ||
                          pathname.includes("Approval") ||
                          pathname.includes("Report") ||
                          pathname.includes("DepartmentManagement") ||
                          pathname.includes("ExamSetup") ? (
                            <Box
                              position={"absolute"}
                              left={0}
                              width={"full"}
                              height={"full"}
                              zIndex={1}
                            />
                          ) : null}
                          <ListIcon
                            zIndex={2}
                            flexShrink={0}
                            flexGrow={0}
                            as={icon}
                            m={0}
                            color={
                              selectedMenu === name ? "#D11A2A" : "#FFFFFF"
                            }
                            // color={pathname.includes(link) ? "#D11A2A" : "rgba(30, 30, 30, 0.5)"}
                          />
                          <Text
                            maxW={[null, null, null, "80px", "150px"]}
                            zIndex={2}
                            as={"span"}
                            flexGrow={1}
                            color={
                              selectedMenu === name ? "#D11A2A" : "#FFFFFF"
                            }
                          >
                            {name}
                          </Text>
                          {selectedMenu === name ? (
                            <BiCaretUp
                              style={{ marginLeft: "auto", color: "red" }}
                            />
                          ) : (
                            <BiCaretDown
                              style={{ marginLeft: "auto", color: "white" }}
                            />
                          )}
                        </Flex>
                      </Box>
                    </ListItem>
                    {selectedMenu === name ? (
                      <Flex direction={"column"}>
                        {submenu.map(({ name, link }) => {
                          return (
                            <Box
                              pl={9}
                              as={NavLink}
                              to={link}
                              py={2}
                              color={
                                pathname.includes(link)
                                  ? "#FFFFFF"
                                  : "#FFFFFF87"
                              }
                            >
                              {({ isActive }) => (
                                <Flex h="full">
                                  {isActive && (
                                    <Box
                                      position={"absolute"}
                                      left={0}
                                      width={"full"}
                                      height={6}
                                      zIndex={1}
                                    />
                                  )}
                                  {name}
                                </Flex>
                              )}
                            </Box>
                          );
                        })}
                      </Flex>
                    ) : null}
                  </React.Fragment>
                );
              })}
            </React.Fragment>
          ))}
        <Flex px={5} gap={5} cursor={"pointer"}    color={
                              "#FFFFFF"
                            } onClick={signOut}>
          <IoLogOut style={{ height: "30px", width: "30px" }} />
          <Text fontWeight={"500"}>Logout</Text>
        </Flex>
      </List>
    </Flex>
  );
};

export default Sidebar;
