import React from 'react';
import { Box, Input, InputGroup, InputLeftElement } from "@chakra-ui/react";
import { BiSearch } from "react-icons/bi";
import {setSearchData} from "../redux/slices/searchFilterSlice";
import { useDispatch } from 'react-redux';

const SearchField = () => {
    const dispatch = useDispatch();
    const handleSearch = (e) => {
      dispatch(setSearchData(e.target.value));
    }

  return (
    <Box px={5} w={'full'}>
    <InputGroup   >
      <InputLeftElement pointerEvents="none">
      <BiSearch color="gray" />
      </InputLeftElement>
      
      <Input placeholder="Search" bg={'#FDD2D430'}
        _placeholder={{ color: "#1C1C1C33" }} focusBorderColor="transparent" border={'none'}
        onChange={handleSearch}
      />
    </InputGroup>
  </Box>
  )
}

export default SearchField;