import React, { useCallback, useEffect, useState} from 'react';
import { Box, Button, Flex, Text , Alert,AlertIcon, AlertTitle } from '@chakra-ui/react';
import UserEmptyState from '../components/UserEmptyState';
import UserMgtTable from '../components/UserMgtTable';
import { LuUpload } from "react-icons/lu";
import Create from '../components/layout/Create';
import { useDispatch, useSelector} from 'react-redux';
import {setFormModal} from "../redux/slices/modalSlice";
import SortBy from '../components/SortBy';
import {fetchUsers} from "../redux/slices/userMgtUsersSlice";
import { checkPermissions} from '../components/CheckPermission';
import {clearsearchFilter} from "../redux/slices/searchFilterSlice";
import {fetchLookupRole, fetchLookupDepartment} from "../redux/slices/lookupSlice";
import DotLoader from '../components/loaders/DotLoader';

const UserMgtUsers = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const errorsArray = Array.isArray(error) ? error : [error];
  const usermgt = useSelector((state) => state?.userMgtUsers);

  const getAllUser = useCallback( async() => {
    setLoading(true);
    setError("");
    try {
      const response = await dispatch(fetchUsers());

      if (response?.payload?.name === "AxiosError"){
        setLoading(false);
        setError(response?.payload?.response?.data?.errors?.map((errors) => errors) || response?.payload?.response?.data?.error?.description || "Failed to get users, please check your request/network");
      }
      setLoading(false);
      
    } catch (error) {
      setError(error?.payload?.message || "Failed to get users, please check your network");
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(clearsearchFilter());
    dispatch(fetchLookupRole());
    dispatch(fetchLookupDepartment());
    getAllUser();
  },[dispatch, getAllUser, usermgt?.usersParams]);

  const userData = useSelector((state) => state?.userMgtUsers?.allUsers?.data);
  const addUserPermission = checkPermissions( 'User Management', 'Users', 'Create', 'Granted' );
  const createData = {
    onClick: () => { dispatch(setFormModal({status:true, title: "Add User", type:"addUser",})); },
    createText: "Add user",
  }

  return (
    <Box bg={'#FFFFFF'} py={'30px'} minH={'100vh'} px={3}>
      <Flex flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} gap={'20px'} pb={2} mb={5}>
        <Text fontSize={'18px'} fontWeight={'700'} color={'#D11A2A'}>USERS</Text>
        {
          userData?.length > 0 ? (
          <Flex flexDirection={['column', 'column','row']} gap={3}  justifyContent={['center', 'center', 'flex-start']} alignItems={'center'}>
            {
              addUserPermission? (
                <>
                  <Button rightIcon={<LuUpload />} color={'#D11A2A'} fontSize={'14px'} fontWeight={'500'} lineHeight={'16px'}
                    bg={'transparent'} _hover={{ bg: 'transparent' }}
                  >Bulk upload
                  </Button>
                  <Create {...createData} />
                </>
              ) : null
            }
            <SortBy show = {true} />
          </Flex>
          ) : null
        }
        
      </Flex>
      {
        loading ? (
          <Flex justifyContent={'center'} alignItems={'center'}>
            <DotLoader />
          </Flex>
        ):(
          <>
            {
              userData?.length > 0 ? (
                <UserMgtTable  />
              ) : error ? (
                <Alert status="error" className="mt-3" mb={3}>
                    <AlertIcon />
                    <AlertTitle>{errorsArray.join(', ')}</AlertTitle>
                </Alert>
                )
              : (
                <UserEmptyState createData={createData} text={"User"} />
              )
            }
          </>
        )
      }
      
    </Box>
  )
}

export default UserMgtUsers;