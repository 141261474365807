import React, { useCallback, useEffect, useState } from 'react';
import { Alert, AlertIcon, AlertTitle, Box, Flex, Text } from '@chakra-ui/react';
import CustomTable from '../components/CustomTable';
// import { feeTypeData } from '../data/mock';
import { setFormModal } from '../redux/slices/modalSlice';
import { useDispatch, useSelector } from 'react-redux';
import Create from '../components/layout/Create';
import {  checkPermissions  } from '../components/CheckPermission';
import SortBy from '../components/SortBy';
import { fetchFeeType} from '../redux/slices/feeMgtSlice';
import { clearsearchFilter } from '../redux/slices/searchFilterSlice';
import DotLoader from '../components/loaders/DotLoader';


function FeeType() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const errorsArray = Array.isArray(error) ? error : [error];

  const getAllFeeType = useCallback( async() => {
    setLoading(true);   
    setError("");
    try {
      const response = await dispatch(fetchFeeType());
      if (response?.payload?.name === "AxiosError"){
        setLoading(false);
        setError(response?.payload?.response?.data?.errors?.map((errors) => errors) || response?.payload?.response?.data?.error?.description || "Failed to get Fee Type, please check your request/network");
      }
      
    } catch (error) {
      setLoading(false);
      setError(error?.payload?.message || "Failed to get Fee Type, please check your network");
    }finally{
      setLoading(false);
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(clearsearchFilter());
    getAllFeeType();
  }, [dispatch, getAllFeeType]);


  const feeTypeData = useSelector((state) => state?.feeMgt?.feeType?.data);
  console.log('feeTypeData', feeTypeData)
  
  const addFeeTypePermission = checkPermissions( 'Fee Management', 'FeeTypes', 'Create', 'Granted' );

  const createData = {
    onClick: () => { dispatch(setFormModal({ status: true, title: "Add Fee Type", type: "addFeeType", })); },
    createText: "Add Fee Type",
  }
  // const handleEdit = (item) => {
  //   dispatch(setEditFeeType(item))
  //   dispatch(setFormModal({ status: true, title: "Edit Fee Type", type: "editFeeType" }))
  // }

  // const handleDelete = async (item) => {
  //   setError("");
  //   try {
  //     const response = await httpRequest(
  //       `${BASE_URL}/v2/Fee/Type${item?.id}`,
  //       "delete",
  //     );
  //     if (response?.isSuccess === true) {
  //       setError('');
  //       dispatch(fetchFeeType());
  //       dispatch(setloadTost({ title: 'Fee Type Deleted', description: 'Fee Type deleted successfully', status: 'success', duration: 5000, isClosable: true, position: 'top' }));
  //     } else {
  //       setError(response?.response?.data?.errors?.map((errors) => errors) || response?.response?.data?.error?.description || "Failed to delete fee");
  //     }
      
  //   } catch (error) {
  //     setError(error?.payload?.message || "Failed to delete fee type, please check your network");
  //   }
  // }

  const tableProps = {
    data: feeTypeData,
    title: {
        feeType: "Fee Type",
    },
    dataKeys: [
        "feeType",
    ],
    transformRow: (item) => ({
        feeType: item?.name && item?.name,
    }),
};

  return (
    <Box py={'30px'} minH={'100vh'} bgColor={'#FFF'}>
      <Flex flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} gap={'20px'} px={'20px'} borderBottom={'1px solid #B4B1B1'} pb={2} mb={5}>
        <Text fontSize={'18px'} fontWeight={'700'}  color={'#D11A2A'} opacity={'50%'}>Fee Type</Text>
        <Flex gap={[3, null, null]} flexDirection={["column", "column", "row"]}  justifyContent={'center'} alignItems={'center'} >
          {
            addFeeTypePermission? (
              <Create {...createData} />
            ) : null
          }
        <SortBy />
        </Flex>
      </Flex>
      {
        loading ? (
          <Flex justifyContent={'center'} alignItems={'center'}>
            <DotLoader />
          </Flex>
        ): (
          <>
            {
            error ? (
              <Alert status="error" className="mt-3" mb={3}>
                  <AlertIcon />
                  <AlertTitle>{errorsArray.join(', ')}</AlertTitle>
              </Alert>
              ) 
              : (<CustomTable {...tableProps} />)
            }
          </>
        )
      }
    </Box>
  )
}

export default FeeType